
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import MachineCard from "@/components/pathway/machine_card.vue";
import MachineActivity from "@/components/machine/machine_activity.vue";
import MachineTimeline from "@/components/machine/machine_timeline.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "machine_show",
  props: {
    id: {
      type: String,
      default: null,
    },
    machine_id: {
      type: String,
      default: null,
    }
  },
  components: {
    MachineCard,
    MachineActivity,
    MachineTimeline,
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageTitle("Machines List");
      
    });

    const store = useStore();
    const router = useRouter(); 
    console.log(props.id)

    store.commit(Mutations.SET_CURRENT_MACHINE, {});
    store.dispatch(Actions.GET_MACHINE, { id: props.machine_id }).then((data) => {});
    store.dispatch(Actions.GET_PATHWAY, props.id);

    const machine = computed(() => {
      return store.getters.getCurrentMachine;
    });

    const pathway = computed(() => {
      return store.getters.getCurrentPathway;
    });

    return {
      moment,
      machine,
      store,
      pathway
    };
  },
});
